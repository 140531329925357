import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import toast from "react-hot-toast"
import { currentUrl } from "../../url.js"

const user = JSON.parse(localStorage.getItem("user"))
const step = user?.step
const id = user?.id
const accessToken = localStorage.getItem("accessToken")
const salon_id = localStorage.getItem("salon_id")
  ? localStorage.getItem("salon_id")
  : user?.salon_id

interface IMedia {
  original_url: string
  collection_name: string
}

// type WorkSchedule = {
//   id: number;
//   day: string;
//   start_time: string;
//   end_time: string;
//   launch_start_time: string;
//   launch_end_time: string;
// };

type Setting = {
  id: number
  name: string
  phone: string
  description: string
  street: string
  building: string
  website: string
  facebook: string
  instagram: string
  avatar: string
  city_id: number
  category_id: number
  status_id: number
  media: IMedia[]
  type: 1 | 2
  // workSchedules: WorkSchedule[];
}

export type newSetting = Omit<
  Setting,
  "avatar" | "media" | "type" | "status_id"
>

type Media = {
  file: File
  collection_name: string
  model_id: number
  id: number
}

type SettingState = {
  list: Setting
  loading: boolean
  error: string | null
  postLoading: boolean
  postError: boolean
  mediaLoading: boolean
  mediaError: boolean
  status_id: number
}

export const fetchSettings = createAsyncThunk<
  Setting,
  string,
  { rejectValue: string }
>(
  "settings/fetchSettings",

  async function (salonId, { rejectWithValue }) {
    if (accessToken && salon_id) {
      console.log(salon_id, "salonId")
      const response = await fetch(`${currentUrl}/master/salons/${salon_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        return rejectWithValue("Ошибка при загрузке данных, попробуйте позже")
      }

      const data = await response.json()

      const processedData: Setting = {
        id: data.id,
        name: data.name || "",
        phone: data.phone || "",
        description: data.description || "",
        street: data.street || "",
        building: data.building || "",
        website: data.website || "",
        facebook: data.facebook || "",
        instagram: data.instagram || "",
        avatar: data.avatar[data.avatar.length - 1]?.original_url || "",
        city_id: data.city_id || 0,
        category_id: data.category_id || 0,
        status_id: data.status_id,
        type: data.salon_type.id || 0,
        // workSchedules: data.workSchedules.map((schedule: any) => ({
        //     id: schedule.id || 0,
        //     day: schedule.day || "",
        //     start_time: schedule.start_time  || "",
        //     end_time: schedule.end_time || "",
        //     launch_start_time: schedule.lunch_start_time || "",
        //     launch_end_time: schedule.lunch_end_time || "",
        //   })) || [],
        media: data.media.map(
          (item): IMedia => ({
            original_url: item.original_url,
            collection_name: item.collection_name,
          })
        ),
      }
      return processedData
    }
  }
)

export const postSettings = createAsyncThunk<
  newSetting,
  newSetting,
  { rejectValue: boolean }
>(
  "settings/postSettings",
  async function ({ id, ...body }, { rejectWithValue }) {
    body.phone = body?.phone?.replace(/-/g, "").slice(-9)
    try {
      const res = await fetch(`${currentUrl}/master/salons/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(body),
      })

      toast.success("Настройки изменены", { position: "bottom-right" })
      const data = await res.json()
      const processedData: newSetting = {
        id: data.id,
        name: data.name,
        phone: data.phone,
        description: data.description,
        street: data.street,
        building: data.building,
        website: data.website,
        facebook: data.facebook,
        instagram: data.instagram,
        city_id: data.city_id,
        category_id: data.category_id,
        // workSchedules: data.workSchedules,
      }

      return processedData
    } catch (err) {
      console.error(err)
      rejectWithValue(true)
      toast.error("Ошибка при изменении настроек")
    }
  }
)

export const postMedia = createAsyncThunk<any, Media, { rejectValue: boolean }>(
  "settings/postMedia",
  async function ({ model_id, file, collection_name }, { rejectWithValue }) {
    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("collection_name", collection_name)
      formData.append("model_id", String(model_id))
      const res = await fetch(
        `${currentUrl}/master/salons/${model_id}/update-image`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: formData,
        }
      )

      return res.json()
    } catch (err) {
      rejectWithValue(true)
      toast.error("Ошибка при изменении медиафайлов")
    }
  }
)

const initialState: SettingState = {
  list: {
    id: 0,
    name: "",
    phone: "",
    description: "",
    street: "",
    building: "",
    website: "",
    facebook: "",
    instagram: "",
    avatar: "",
    city_id: 0,
    category_id: 0,
    status_id: 0,
    media: [],
    type: 1,
    // workSchedules: [],
  },
  status_id: 1,
  loading: false,
  error: null,
  postError: false,
  postLoading: false,
  mediaError: false,
  mediaLoading: false,
}

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    setStatusId(state, action) {
      state.status_id = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.list = action.payload
        state.loading = false
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(postSettings.pending, (state) => {
        state.postError = false
        state.postLoading = true
      })
      .addCase(postSettings.fulfilled, (state) => {
        state.postLoading = false
      })
      .addCase(postSettings.rejected, (state) => {
        state.postError = true
        state.postLoading = false
      })
      .addCase(postMedia.pending, (state) => {
        state.mediaLoading = true
        state.mediaError = false
      })
      .addCase(postMedia.fulfilled, (state) => {
        state.mediaLoading = false
      })
      .addCase(postMedia.rejected, (state) => {
        state.mediaError = true
        state.mediaLoading = false
      })
  },
})

export const { setStatusId } = settingsSlice.actions

export default settingsSlice.reducer