import { useEffect, useRef, useState } from "react"
import { useFormik } from "formik"

import { PasswordInput } from "../../shared/ui/input/PasswordInput"
import { GoBackBtn } from "./GoBackBtn"
import { schemes } from "./validations"

export const CreatePassword = ({ submit, backSlide, hidden2 }) => {
  const [timer, setTimer] = useState(0)
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  const validate = (values) => {
    const errors = {}

    // Disallow spaces in the password
    if (/\s/.test(values.password)) {
      errors.password = "Пароль не должен содержать пробелы"
    } else if (values.password.length < 8) {
      errors.password = "Пароль должен содержать минимум 8 символов"
    }

    if (/\s/.test(values.repeatPassword)) {
      errors.repeatPassword = "Пароль не должен содержать пробелы"
    } else if (values.repeatPassword !== values.password) {
      errors.repeatPassword = "Пароли должны совпадать"
    }

    return errors
  }

  const { values, handleChange, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: schemes.getSchemeOf(["password", "repeatPassword"]),
    validate,
    onSubmit: () => {
      submit(values.password)
      resetForm()
      setTimer(3)
    },
  })

  const repeatRef = useRef(null)

  return (
    <div
      className={`max-w-[344px] w-full mt-[10px] sm:mt-0 ${
        hidden2 && "hidden"
      }`}
    >
      <div className='text-center mx-auto mb-6 [&>p]:text-darkGray [&>p]:text-sm'>
        <h1 className='text-[27px] font-medium mb-6'>Введите новый пароль</h1>
      </div>
      <div className='flex flex-col mobile:gap-40 bigMobile:gap-60 sm:gap-0'>
        <div className='text-left flex flex-col w-full sm:mb-8'>
          <PasswordInput
            className='mb-8'
            label={"Новый пароль"}
            name={"password"}
            value={values.password}
            handleValue={handleChange}
            error={touched.password && errors.password}
            placeholder='Введите новый пароль'
            onKeyDown={(e) =>
              e.code === "Enter" &&
              !errors.password &&
              repeatRef.current.focus()
            }
          />
          <label ref={repeatRef}>
            <PasswordInput
              label={"Подтвердите пароль"}
              name={"repeatPassword"}
              value={values.repeatPassword}
              handleValue={handleChange}
              placeholder='Введите пароль повторно'
              error={touched.repeatPassword && errors.repeatPassword}
              onKeyDown={(e) =>
                e.code === "Enter" && !errors.repeatPassword && handleSubmit()
              }
            />
          </label>
        </div>
        <div>
          <button
            type='button'
            disabled={timer > 0}
            onClick={handleSubmit}
            className='btnFilled w-full '
          >
            Далее
          </button>
          <GoBackBtn handleClick={backSlide} />
        </div>
      </div>
    </div>
  )
}
